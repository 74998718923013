import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';

import { Animation3dComponent } from './animation3d/animation3d.component';

import  { polyfill } from "smoothscroll-polyfill";
polyfill();

@NgModule({
  declarations: [
    AppComponent,
    Animation3dComponent
  ],
  imports: [
    BrowserModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

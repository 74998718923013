import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})



export class AppComponent {


  current_app = 1;
  apps_qtt    = 6;
  scroll_top = 0;
  timeout: boolean = false;

  ngOnInit() {
    
    console.log("init");
    setTimeout(()=>{
      if (window.innerHeight <= 700) this.scrollBottom(".about");
    },500);
    setTimeout(()=>{
      this.timeout = true;
    },3000);
  }

  nextApp(){
    console.log("next");
    this.current_app++;
    if (this.current_app > this.apps_qtt) this.current_app = 1;
    this.playVideos();
    setTimeout(()=>{
      this.playVideos();
    })
  }
  prevApp(){
    this.current_app--;
    if (this.current_app < 1) this.current_app = this.apps_qtt;
    this.playVideos();
    setTimeout(()=>{
      this.playVideos();
    })
  }

  setApp(index:number){
    this.current_app = index;
    this.playVideos();
    setTimeout(()=>{
      this.playVideos();
    })
  }

  scrollTop(data){    
    const y = typeof data === "string" ? document.querySelector(data).getBoundingClientRect().top + window.scrollY - 60  : data;
    window.scroll({
      top: y,
      behavior: 'smooth'
    });
  }
  scrollBottom(data){    
    const y =  document.querySelector(data).getBoundingClientRect().top + window.scrollY - window.innerHeight;
    window.scroll({
      top: y,
      behavior: 'smooth'
    });
  }

  playVideos(){
    let list: any = document.querySelectorAll('.slide.current video');
    list.forEach(function(e:any){
        if (e.paused) e.play();          
    })
  }

  onWindowScroll(event) {
    this.scroll_top = window.scrollY;
    this.playVideos();
   
  }

  getOpacityOnScroll(scroll_top,selector){
    let element = document.querySelector(selector);
    let top = window.innerHeight - element.getBoundingClientRect().y;
    let k =  Math.min(( (top - 50)/(window.innerHeight/2) ),1);
    return {'opacity': k}
  }

  getTransformOnScroll(scroll_top,selector){
    let element = document.querySelector(selector);
    let top = window.innerHeight - element.getBoundingClientRect().y;
    let k = Math.abs((top - 50)/(window.innerHeight/2));
    let t =  0.6 - k;
    return {transform: "translateX(-50%) translateY(-50%) rotateZ(2deg) rotateX("+(-t*4)+"deg) rotateY("+(t*12)+"deg)"};
  }


  getStylesOnScroll(scroll_top,selector){
    let element = document.querySelector(selector);
    let top = window.innerHeight - element.getBoundingClientRect().y;
    let k = Math.min(Math.abs((top - 50)/(window.innerHeight/2)),1);
    // return {'transform': "translateZ("+(200*(k-1))+"px)"}
    // return {'transform': "rotateX("+(40*(k-1))+"deg)"}
    //  return {'filter': "blur("+(4*(1-k))+"px)"}
    //  return {'filter': "invert("+(100*(1-k))+"%)"}
  }
}

const tag_color:                string = "#29d4ff";
const attribute_name_color:     string = "#fcff99";
const attribute_value_color:    string = "#ffce99";
const symbol_color:             string = "#dfffd2";
const content_color:            string = "#ffffff";

import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import {  BokehPass  } from 'three/examples/jsm/postprocessing/BokehPass.js';
import {  GammaCorrectionShader  } from 'three/examples/jsm/shaders/GammaCorrectionShader.js';

const width  = window.innerWidth;
const height = window.innerHeight*0.8;//Math.min(window.innerHeight,width);

const devicePixelRatio = Math.min(window.devicePixelRatio,2);

@Component({
  selector: 'app-animation3d',
  templateUrl: './animation3d.component.html',
  styleUrls: ['./animation3d.component.scss'],
})
export class Animation3dComponent implements OnInit, AfterViewInit{

  @Output() onPuzzleSelect = new EventEmitter<boolean>();
  

  @ViewChild("renderer") rendererDomElement: any;

  state:        string                   = "";

  scene:        THREE.Scene               = new THREE.Scene();
  renderer:     THREE.WebGLRenderer       = new THREE.WebGLRenderer({antialias: true, alpha: false});
  camera:       THREE.PerspectiveCamera   = new THREE.PerspectiveCamera( 60, width / height, 0.1, 1000 );  

  ambientLight: THREE.HemisphereLight    = new THREE.HemisphereLight(0xeeeeee, 0xffffff, 1.1);
  pointLight:   THREE.PointLight         = new THREE.PointLight(0xffffff, .1);  

  mixer:        THREE.Mixer;
  clock:        THREE.Clock               = new THREE.Clock();

  raycaster:    THREE.Raycaster           = new THREE.Raycaster();
  mouse:        any                       = {x: 0, y:0}
  CTAMeshes:    Array<THREE.Mesh>         = [];
  SCRAMeshes:    Array<THREE.Mesh>         = [];
  ANIMeshes:    Array<THREE.Mesh>         = [];
  hoveredMesh: THREE.Mesh                 = null; 
  activeMeshName: string                  = ''; 
  activePositoin: any                     = {x:width/2,y:height/2};
  activeText: string                      = "Click to power on Mac Mini";
  mouseDownEvent: any                     = null;

  // composer:     EffectComposer;

  controls: OrbitControls             = new OrbitControls( this.camera, this.renderer.domElement );

  letterE : THREE.Object3D;


  // animationModel: THREE.Object3D;

  constructor() {    
  }

  ngOnInit() {}

  async ngAfterViewInit(){
    
    this.initRenderer();
    await this.loadGLBModel();
    this.state = 'loaded';
    setTimeout(()=>{this.state = 'done'},500)
    this.initLights();
    this.initPrimaryCamera();
    this.initControls();
    this.showSCRA(0,0);
    setTimeout(async ()=>{
      // this.activeMeshName = "cta-mac";
      await this.makeAnimation1();
      await this.makeAnimation2();
    },5000);

    window.onresize = ()=>{
      if (window.innerWidth !== width){
        this.state = "";
        setTimeout(()=>{
          location.reload();
        },1000)
      }
    }
     
  }

  initRenderer(){    
    this.renderer.setSize( width * devicePixelRatio, height * devicePixelRatio );
    this.rendererDomElement.nativeElement.appendChild(this.renderer.domElement);
    this.renderer.domElement.style.width    = `${width}px`;
    this.renderer.domElement.style.height   = `${height}px`;
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    this.renderer.outputEncoding = THREE.sRGBEncoding;    
    this.renderer.setClearColor( 0xfff397, 1 );

    // this.composer = new EffectComposer( this.renderer );

    // const renderPass = new RenderPass( this.scene, this.camera );
    // this.composer.addPass( renderPass );

    // const gammaCorrectionPass = new ShaderPass( GammaCorrectionShader );
    // this.composer.addPass( gammaCorrectionPass );

  

    const bokehPass = new BokehPass( this.scene, this.camera, {
      focus: 1,
      aperture: 0.001,
      maxblur: 0.0026,

      width: width * devicePixelRatio,
      height: height * devicePixelRatio 
    } ); 
    
    // this.composer.addPass( bokehPass );
    

  
    this.renderer.setAnimationLoop( () => {

      var delta = this.clock.getDelta();

				if ( this.mixer ) this.mixer.update( delta );
      this.controls.update();
      this.renderer.render( this.scene, this.camera );     

      // this.composer.render();

      let active = this.CTAMeshes.find(e=> e.name == this.activeMeshName);        
      if (active) this.toScreenPosition(active);        

      // if (bokehPass && active){
      //   let distance = this.camera.position.distanceTo(active.position);
      //   let current = bokehPass.uniforms[ "focus" ].value;
      //   bokehPass.uniforms[ "focus" ].value  += Math.sign(distance-current)*0.2;
      // }      


      // if (this.mouse.x !=0 && this.mouse.y != 0){
        this.hoveredMesh = null;
        this.raycaster.setFromCamera( this.mouse, this.camera );
        let intersects = this.raycaster.intersectObjects( this.CTAMeshes, true );
      
        
        let distance = null;
          for ( var i = 0; i < intersects.length; i++ ) {   
          
              if (distance === null || intersects[i].distance < distance){
                distance = intersects[i].distance;
                if (intersects[ i ].object.parent && intersects[ i ].object.parent.name?.includes("cta-")){
                  this.hoveredMesh = intersects[ i ].object.parent;
                }else{
                  this.hoveredMesh = intersects[ i ].object;
                }
              }        
          }
      // }

      if (window.innerWidth <= 800){
        this.camera.position.lerp(new THREE.Vector3(35,8,20), 0.01);
      }else if (window.innerWidth <= 850){
        this.camera.position.lerp(new THREE.Vector3(22,10,13), 0.01);
      }else{
        this.camera.position.lerp(new THREE.Vector3(10,7,12), 0.01);
      }
      this.pointLight.position.lerp(new THREE.Vector3(this.camera.position.x+13,this.camera.position.y+8,this.camera.position.z+3), 0.1);
    
    } );
  }

  toScreenPosition(obj){
    var vector = new THREE.Vector3();

    var widthHalf = 0.5*width;
    var heightHalf = 0.5*height;

    obj.updateMatrixWorld();
    vector.setFromMatrixPosition(obj.matrixWorld);
    vector.project(this.camera);

    vector.x = ( vector.x * widthHalf ) + widthHalf;
    vector.y = - ( vector.y * heightHalf ) + heightHalf;

    this.activePositoin = { 
        x: vector.x,
        y: vector.y
    };

};

 
  initLights(){    
    this.pointLight.position.set(14, 10, 7);
  
    this.pointLight.shadow.bias = -0.00012;        
    this.pointLight.shadow.mapSize.width = 1024;
    this.pointLight.shadow.mapSize.height = 1024;    
    this.pointLight.shadow.camera.near = 1;
    this.pointLight.shadow.camera.far = 1250;           
   
  
    this.pointLight.shadow.mapSize.width = 1024;
    this.pointLight.castShadow = true;    

    this.scene.add(this.pointLight);   
    this.scene.add(this.ambientLight);
  }

  initPrimaryCamera(){
    //setting inital positon of camera    
    let x = 65, y = 5, z= 10;
    this.camera.position.set(x,y,z);
  }


  initControls(){
    this.controls.target.set(0,2.3,0);
    this.controls.enablePan = false;
		this.controls.enableZoom = false; 
		this.controls.enableDamping = true; 
    this.controls.autoRotate = false;
		this.controls.minPolarAngle = 0.3;
		this.controls.maxPolarAngle = 1.7;
    this.controls.minAzimuthAngle = 0.2;
    this.controls.maxAzimuthAngle = 1.9;
		
		this.controls.dampingFactor = 0.07;
		this.controls.rotateSpeed = 0.5;
    
  }

  async animate1(children){
    children.sp = children.position.z;
    children.a = 0;
    setInterval(()=>{      
        children.position.z = children.sp + Math.cos(children.a)*0.03;              
        children.a +=0.1;
        if (children.a > Math.PI*4){
          children.a = 0;
        }
    },10)
  }

  async showSCRA(id,timeout){
    return new Promise((resolve, reject)=>{
      for (let item of this.SCRAMeshes){
        item.visible = item.name.includes("scra-"+id);
      }
      setTimeout(()=>{
        resolve(true);
      },timeout)
    });
  }

  async loadGLBModel(){
    return new Promise((resolve)=>{
       
      new  GLTFLoader().load("assets/glb/table.glb",(model: GLTF)=>{
        //success event                
           this.mixer = new THREE.AnimationMixer( model.scene );
           
          for (let children of model.scene.children ){
            // let children = model.scene.children.find(child => child.name === `part-${i}`);

            if (children){              
              children.castShadow = true;
              children.receiveShadow = true;
              if (children.name){
                if (children.name.includes("cta-")){
           
                //  this.CTAMeshes.push(children);
                }                
                if (children.name.includes("scra-")){
           
                 this.SCRAMeshes.push(children);
                }   
                if (children.name.includes("animation-1")){
           
                  this.ANIMeshes.push(children);
                  this.animate1(children)
                 }                
              }                                    
            }
          }          
          

          for (let animation of model.animations){

            let action = this.mixer.clipAction( animation );
          
            
            // action.setLoop( THREE.LoopOnce )
            action.clampWhenFinished = true
            action.enable = true          
            setTimeout(()=>{
              action.play();
            },2000)
          }
          this.scene.add(model.scene);             

          setTimeout(()=>{            
            // this.controls.autoRotate = true;
          },4000)

          resolve(model.scene);      

      },(event)=>{
        // onprogress event
        // this.progress = 100*event.loaded/event.total        
      },()=>{
        // error event
        console.error("glTF resource load problem");        
        resolve(null);
      });
    });
  }

  scrollTop(data){    
    const y = typeof data === "string" ? document.querySelector(data).getBoundingClientRect().top + window.scrollY - 60  : data;
    window.scroll({
      top: y,
      behavior: 'smooth'
    });
  }

  mousemove(event){
      const x = event.changedTouches ? event.changedTouches[0].clientX : event.clientX;
      const y = event.changedTouches ? event.changedTouches[0].clientY : event.clientY;
      this.mouse.x =   ( x / width  ) * 2 - 1;
      this.mouse.y = - ( y / height ) * 2 + 1;  
      

      // console.log(this.mouse)
  }

  mousedown(event){
    this.mouseDownEvent = {
      x: event.clientX,
      y: event.clientY,
      time: new Date().getTime()
    }
  }

  async makeAnimation1(){
    this.activeText = "";       
          await this.showSCRA(1,2000);
          await this.showSCRA(2,5000);
          await this.showSCRA(3,1000);
          await this.showSCRA(4,10);          
          this.activeMeshName = 'cta-keyboard';
          this.activeText = "Enter PIN";       
  }
  async makeAnimation2(){
    this.activeText = "";       
    await this.showSCRA(5,3000);
    await this.showSCRA(6,10);
    // this.activeMeshName = 'cta-mouse';
    // this.activeText = "Scroll Down";  
    this.activeMeshName = '';
    this.activeText = "";   
  }
  async makeAnimation3(){
    this.activeMeshName = '';
    this.activeText = "";      
    this.scrollTop('.about');  
  }

  async mouseup(event){
    let dx = Math.abs(event.clientX - this.mouseDownEvent.x);
    let dy = Math.abs(event.clientX - this.mouseDownEvent.x);
    let dt = new Date().getTime() - this.mouseDownEvent.time;


    if (this.activeText && this.hoveredMesh && this.hoveredMesh.name && dx < 10 && dy < 10 && dt < 160){
      console.log(this.hoveredMesh.name, this.activeMeshName);
      if (this.hoveredMesh.name === this.activeMeshName){      
        // if (this.hoveredMesh?.name === 'cta-mac'){   
        //  this.makeAnimation1();
        // }
        // if (this.hoveredMesh?.name === 'cta-keyboard'){          
        //   this.makeAnimation2();    
        // }
        // if (this.hoveredMesh?.name === 'cta-mouse'){
        // //  this.makeAnimation3();
        // }
      }
    }
  }


}

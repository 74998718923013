<header (window:scroll)="onWindowScroll($event)">
  <div class="logo" (click)="scrollTop('.start')"></div>
  <nav>
    <div class="item" (click)="scrollTop('.start')">Start</div>
    <div class="item" (click)="scrollTop('.about')">About</div>
    <div class="item" (click)="scrollTop('.portfolio')">Portfolio</div>
    <div class="item" (click)="scrollTop('.cv')">CV</div>
    <div class="item" (click)="scrollTop('.contact')">Contact</div>
  </nav>
</header>

<section class="start">
  <app-animation3d></app-animation3d>
  <div class="info" [ngClass]="{show: timeout}">
    <div class="image">
      <img src="assets/img/icons/rotation.svg" alt="">
    </div>
    <div class="text">     
      Camera position<br>
      is under your control
    </div>
  </div>
  <div class="info button" (click)="scrollTop('.about')" [ngClass]="{show: timeout}">
    <div class="image">
      <img src="assets/img/icons/down.svg" alt="">
    </div>
    <div class="text">   
      Scroll down
    </div>
  </div>
</section>
<div class="transform_wraper">
<section class="about" [ngStyle]="getStylesOnScroll(scroll_top,'.about')">
  <img class="my-photo" [ngStyle]="getOpacityOnScroll(scroll_top,'.about')" src="assets/img/photo.jpg">
  <div class="text">
    <h1>About me</h1>
    <p>
        Hi, I'm Viacheslav Komarivskyi, a JavaScript Developer from Poland, currently living in Piaseczno.<br>
        I have 10 years of experience as a developer and love working with Angular, Ionic and Three.js frameworks/libraries.<br>
        My portfolio contains dozens of commercial projects and private apps.
    </p>  
    <a  target="_blank" href="assets/Viacheslav-Komarivskyi-CV.pdf" class="btn" (click)="scrollTop('.about')" [ngClass]="{show: timeout}">
      <div class="image">
        <img src="assets/img/icons/download.svg" alt="">
      </div>
      <div class="btn-text">   
        Download CV
      </div>
    </a>
  </div>
</section>
<section class="sotial">
  <div class="title">Find me in sotial networks:</div>
  <a target="_blank" href="https://www.instagram.com/komarivskyi/">
    <img src="/assets/img/icons/instagram.svg" alt="">
  </a>
  <a target="_blank" href="https://www.linkedin.com/in/viacheslav-komarivskyi-49b16284/">
    <img src="/assets/img/icons/linkedin.svg" alt="">
  </a>    
  <a target="_blank" href="https://dribbble.com/Komarivskyi">
    <img src="/assets/img/icons/dribble.svg" alt="">
  </a>        
</section>
<section class="portfolio">
    <div class="relative">
  <h1>Portfolio</h1>
  <p>
      This portfolio contents some of my favourite apps. 
  </p>
    </div>
<div class="relative">
    <div class="button button_left" (click)="prevApp()">
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="451.847px" height="451.847px" viewBox="0 0 451.847 451.847" style="enable-background:new 0 0 451.847 451.847;" xml:space="preserve"><g><path d="M97.141,225.92c0-8.095,3.091-16.192,9.259-22.366L300.689,9.27c12.359-12.359,32.397-12.359,44.751,0 c12.354,12.354,12.354,32.388,0,44.748L173.525,225.92l171.903,171.909c12.354,12.354,12.354,32.391,0,44.744 c-12.354,12.365-32.386,12.365-44.745,0l-194.29-194.281C100.226,242.115,97.141,234.018,97.141,225.92z"/></g></svg>
      </div>
      <div class="button button_right" (click)="nextApp()">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 451.846 451.847" style="enable-background:new 0 0 451.846 451.847;" xml:space="preserve"><g><path d="M345.441,248.292L151.154,442.573c-12.359,12.365-32.397,12.365-44.75,0c-12.354-12.354-12.354-32.391,0-44.744 L278.318,225.92L106.409,54.017c-12.354-12.359-12.354-32.394,0-44.748c12.354-12.359,32.391-12.359,44.75,0l194.287,194.284 c6.177,6.18,9.262,14.271,9.262,22.366C354.708,234.018,351.617,242.115,345.441,248.292z"/></g></svg>
      </div>
  <div class="portfolio_slider">
      <!-- <div class="buttons"> -->
      <!-- </div> -->        

        <div class="slide" [ngClass]="{current: current_app === 1}">
          <div class="screens">
            <video oncanplay="this.muted=true" muted="muted" autoplay loop="loop" src="assets/video/home.mp4"></video>
            <video oncanplay="this.muted=true" muted="muted" autoplay loop="loop" src="assets/video/game.mp4"></video>
            <video oncanplay="this.muted=true" muted="muted" autoplay loop="loop" src="assets/video/more.mp4"></video>
          </div>
          <div class="text">
            <img class="name" src="assets/img/apps/rocket_calculations.png">
            <div class="description">
                Rocket Calculations ia a multiplayer math game. Play with other users, raise your rating and increase your mental calcuation skills.
            </div>
            <div class="technologies">
              <h3>Technologies:</h3>
              <div class="icon_item">
                <img src="assets/img/icons/Ionic.svg"> Ionic 5
              </div>
              <div class="icon_item">
                <img src="assets/img/icons/Angular.svg"> Angular
              </div>
              <div class="icon_item">
                <img src="assets/img/icons/Nodejs.svg"> NodeJS
              </div>
              <div class="icon_item">
                <img src="assets/img/icons/Mongodb.svg"> MongoDB
              </div>
            </div>
            <div class="distribution">
              <h3>Distribution:</h3>
              <div class="icon_item">
                <img src="assets/img/icons/Googleplay.svg"> Google Play
              </div>
              <div class="icon_item">
                <img src="assets/img/icons/Appstore.svg"> App Store
              </div>
            </div>
            <div class="downloads">
              <h3>Total downloads:</h3>
              <div class="value">
                3000+
              </div>
            </div>
          </div>
        </div>

        <div class="slide" [ngClass]="{current: current_app === 2}">
          <div class="screens">
            <video oncanplay="this.muted=true" muted="muted" autoplay loop="loop" src="assets/img/apps/screens/profesox.mp4"></video>
          </div>
          <div class="text">
            <div class="name-text">
              profesox.pl
            </div>        
            <div class="description">
              Application for determining thermal parameters of building partitions. <br>
              
            </div>
            <div class="technologies">
              <h3>Technologies:</h3>
              <div class="icon_item">
                <img src="assets/img/icons/Angular.svg"> Angular
              </div>
              <div class="icon_item">
                <img src="assets/img/icons/php.svg"> PHP
              </div>
              <div class="icon_item">
                <img src="assets/img/icons/mysql.svg"> MySql
              </div>
            </div>
            <div class="distribution">
              <h3>Distribution:</h3>
              <div class="icon_item">
                <img src="assets/img/icons/web.svg"> WEB
              </div>          
            </div>     
          </div>
        </div>

        <div class="slide" [ngClass]="{current: current_app === 3}">
          <div class="screens">
            <video oncanplay="this.muted=true" muted="muted" autoplay loop="loop" src="assets/video/start.mp4"></video>
            <video oncanplay="this.muted=true" muted="muted" autoplay loop="loop" src="assets/video/help.mp4"></video>
            <video oncanplay="this.muted=true" muted="muted" autoplay loop="loop" src="assets/video/levels.mp4"></video>
          </div>
          <div class="text">
            <img class="name" src="assets/img/apps/math_puzzles.png">
            <div class="description">
              It's all about enjoying thinking and solving a problem.<br>
              App contains 30 challenging brain puzzles. Each of them offers a different and unique challenge which forces you to think.
            </div>
            <div class="technologies">
              <h3>Technologies:</h3>
              <div class="icon_item">
                <img src="assets/img/icons/Ionic.svg"> Ionic 3
              </div>
              <div class="icon_item">
                <img src="assets/img/icons/Angular.svg"> Angular
              </div>
            </div>
            <div class="distribution">
              <h3>Distribution:</h3>
              <div class="icon_item">
                <img src="assets/img/icons/Googleplay.svg"> Google Play
              </div>
              <div class="icon_item">
                <img src="assets/img/icons/Appstore.svg"> App Store
              </div>
            </div>
            <div class="downloads">
              <h3>Total downloads:</h3>
              <div class="value">
                2000+
              </div>
            </div>
          </div>
        </div>
 
    <div class="slide" [ngClass]="{current: current_app === 4}">
      <div class="screens">
        <video oncanplay="this.muted=true" muted="muted" autoplay loop="loop" src="assets/video/v1.mp4"></video>
        <video oncanplay="this.muted=true" muted="muted" autoplay loop="loop" src="assets/video/v2.mp4"></video>
        <video oncanplay="this.muted=true" muted="muted" autoplay loop="loop" src="assets/video/v3.mp4"></video>
      </div>
      <div class="text">
        <img class="name" src="assets/img/apps/flags_quiz.png">
        <div class="description">
          At the "Craft Flags" mode you have to select shape, emblem and colors of the country flag. If you think you area good at flags quiz applications - try to play our "Flagq Quiz" and remember every detail of flag.
        </div>
        <div class="technologies">
          <h3>Technologies:</h3>
          <div class="icon_item">
            <img src="assets/img/icons/Ionic.svg"> Ionic 1
          </div>
          <div class="icon_item">
            <img src="assets/img/icons/Angular.svg"> Angular.js
          </div>
        </div>
        <div class="distribution">
          <h3>Distribution:</h3>
          <div class="icon_item">
            <img src="assets/img/icons/Googleplay.svg"> Google Play
          </div>
          <div class="icon_item">
            <img src="assets/img/icons/Appstore.svg"> App Store
          </div>
        </div>
        <div class="downloads">
          <h3>Total downloads:</h3>
          <div class="value">
            3000+
          </div>
        </div>
      </div>
    </div>
    <div class="slide" [ngClass]="{current: current_app === 5}">
      <div class="screens">
        <img src="assets/img/apps/screens/1.png" alt="">
        <img src="assets/img/apps/screens/2.png" alt="">
        <img src="assets/img/apps/screens/3.png" alt="">
      </div>
      <div class="text">
        <img class="name" src="assets/img/apps/poland.png">
        <div class="description">
          #1 application for learning Polish language :) <br>
          Back in 2014 we were the first Polish language learning app with TTS and Speech Recognition Api.
        </div>
        <div class="technologies">
          <h3>Technologies:</h3>
          <div class="icon_item">
            <img src="assets/img/icons/Ionic.svg"> Ionic 1
          </div>
          <div class="icon_item">
            <img src="assets/img/icons/Angular.svg"> Angular.js
          </div>
        </div>
        <div class="distribution">
          <h3>Distribution:</h3>
          <div class="icon_item">
            <img src="assets/img/icons/Googleplay.svg"> Google Play
          </div>
          <div class="icon_item">
            <img src="assets/img/icons/Appstore.svg"> App Store
          </div>
        </div>
        <div class="downloads">
          <h3>Total downloads:</h3>
          <div class="value">
            50 000+
          </div>
        </div>
      </div>
    </div>
    <div class="slide" [ngClass]="{current: current_app === 6}">
      <div class="screens">
        <img src="assets/img/apps/screens/m1.png" alt="">
        <img src="assets/img/apps/screens/m2.png" alt="">
        <img src="assets/img/apps/screens/m3.png" alt="">
      </div>
      <div class="text">
        <img class="name" src="assets/img/apps/mc.png">
        <div class="description">
          Compete with friends or pass training levels and improve your mental calculation skills.
        </div>
        <div class="technologies">
          <h3>Technologies:</h3>
          <div class="icon_item">
            <img src="assets/img/icons/Ionic.svg"> Ionic 1
          </div>
          <div class="icon_item">
            <img src="assets/img/icons/Angular.svg"> Angular.js
          </div>
        </div>
        <div class="distribution">
          <h3>Distribution:</h3>
          <div class="icon_item">
            <img src="assets/img/icons/Googleplay.svg"> Google Play
          </div>
          <div class="icon_item">
            <img src="assets/img/icons/Appstore.svg"> App Store
          </div>
        </div>
        <div class="downloads">
          <h3>Total downloads:</h3>
          <div class="value">
            45 000+
          </div>
        </div>
      </div>
    </div>    
  </div>

  </div>

  <div class="paggination">
    <div class="item" [ngClass]="{current: current_app === 1}" (click)="setApp(1)"></div>
    <div class="item" [ngClass]="{current: current_app === 2}" (click)="setApp(2)"></div>
    <div class="item" [ngClass]="{current: current_app === 3}" (click)="setApp(3)"></div>
    <div class="item" [ngClass]="{current: current_app === 4}" (click)="setApp(4)"></div>
    <div class="item" [ngClass]="{current: current_app === 5}" (click)="setApp(5)"></div>
    <div class="item" [ngClass]="{current: current_app === 6}" (click)="setApp(6)"></div>    
  </div>
</section>
<section class="sotial">
  <div class="title">Visit my stores:</div>
  <a href="https://play.google.com/store/apps/developer?id=Viacheslav+Komarivskyi" target="_blank">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512.029 512.029" style="enable-background:new 0 0 512.029 512.029;" xml:space="preserve"><g><g><path d="M111.758,8.189C91.726-2.883,68.206-2.627,48.302,8.349l233.28,215.2l78.368-78.368L111.758,8.189z"/></g></g><g><g><path d="M24.974,30.365c-5.696,9.312-8.96,19.968-8.96,31.264v388.672c0,10.944,2.976,21.376,8.352,30.496l234.592-234.592L24.974,30.365z"/></g></g><g><g><path d="M463.854,202.525l-74.752-41.248l-84,83.968l102.944,94.944l55.84-30.816c20.096-11.136,32.128-31.104,32.128-53.44C495.982,233.597,483.982,213.629,463.854,202.525z"/></g></g><g><g><path d="M282.478,267.901L47.246,503.133c10.208,5.824,21.408,8.896,32.672,8.896c10.88,0,21.824-2.752,31.84-8.288l266.784-147.232L282.478,267.901z"/></g></g></svg>
  </a>
  <a href="https://apps.apple.com/pl/developer/viacheslav-komarivskyi/id1076167227" target="_blank">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 430.117 430.117" style="enable-background:new 0 0 430.117 430.117;" xml:space="preserve"><g><path id="App_Store" d="M0,258.734v-58.366c0-5.346,4.392-9.719,9.772-9.719H112.91l-44.904,77.817H9.772C4.392,268.466,0,264.102,0,258.734z M73.469,378.249l-32.915,22.3c-4.429,3.01-7.561,1.105-6.947-4.21l4.392-38.153c0.611-5.315,4.921-7.486,9.582-4.83l25.473,14.552C77.707,370.577,77.898,375.248,73.469,378.249z M81.373,356.743l-25.473-14.557c-4.657-2.66-6.298-8.625-3.589-13.264l117.954-204.469c2.676-4.646,8.708-6.275,13.362-3.58l25.473,14.519c4.66,2.695,6.259,8.665,3.589,13.294L94.737,353.169C92.065,357.808,86.028,359.399,81.373,356.743z M171.144,268.466l44.904-77.817h25.856l36.463,77.817H171.144z M317.394,291.688c-10.197-5.092-60.984-121.752-73.996-152.19c-13.026-30.439-52.358-111.462-39.635-117.767c9.049-4.52,41.543,59.281,65.902,97.584c24.324,38.305,75.19,137.068,81.449,148.315c6.227,11.276-5.992,20.676-12.787,23.49C331.525,293.934,327.587,296.739,317.394,291.688z M350.768,345.31l-15.345-24.134c-3.174-5.022-1.526-11.22,3.621-13.866l14.627-7.49c5.157-2.623,11.612-0.495,14.356,4.756l12.105,23.181c2.754,5.241,0.878,11.972-4.088,14.935l-10.459,6.227C360.62,351.918,353.941,350.284,350.768,345.31z M407.244,408.613c-3.206-10.226-27.685-10.902-38.644-26.299c-10.995-15.346-1.908-23.252,1.909-26.901C414.959,330.486,407.244,408.613,407.244,408.613z M430.117,258.734c0,5.367-4.391,9.731-9.772,9.731h-51.627c-0.569-3.001-1.493-6.002-3.053-8.816c-1.344-2.395-4.741-8.854-9.474-17.856c-6.833-12.993-16.647-31.575-27.153-51.144h91.302c5.382,0,9.773,4.373,9.773,9.719v58.366H430.117z"/></g></svg>  
  </a>
</section>
<section class="cv"  [ngStyle]="getStylesOnScroll(scroll_top,'.cv')">
  <div>
  <h1>CV</h1>
  <div class="col_left">
      <h2>Work experience</h2>
      <div class="date_range_item">
        <div class="date">
          since 2022          
        </div>
        <div class="text">
          <h3>Senior Front-End Developer<br>            
            Roche, Poland
          </h3>
          As an ongoing Senior Front-End Developer at Roche, I continue to play a pivotal role in advancing medical imaging rendering solutions.
          <br>
          <br>
          Job Responsibilities:
          <ul>
            <li>
              Development and optimization of the medical imaging viewer rendering platform
                          
            </li>
            <li>              
              Collaborating with cross-functional teams to integrate cutting-edge technologies and ensure continuous improvements in user experiences
            </li>
            <li>
              Demonstrating ongoing strong problem-solving skills by troubleshooting, debugging, and promptly resolving issues related to front-end development, ensuring a reliable and continuously improving user experience
            </li>            
          </ul>
        </div>
      </div>
      <div class="date_range_item">
        <div class="date">
          2016 - 2022<br>
          2013 - 2016<br>
        </div>
        <div class="text">
          <h3>Lead Web Developer<br>
            Front-End Developer<br>
            Medorado, Poland
          </h3>
          Being a lead developer I took an active part in making decisions regarding company strategy in terms of adopting new technologies and generating ideas for a new projects.<br>
          With an understanding of importance of cost and time optimalizations and how this can be beneficial for the company. I was responsible for the choice Ionic/Angular Frameworks for mobile/web apps development, these decisions bring a lot of new projects and clients to Medorado.<br>
          Responding to the demand of pandemic time we developed live streaming platform for medical online events. This well-optimized web application allows us to stream high quality medical content up to 15000 users per event. Great attention was paid to reliability. We have implemented multiply streaming software solutions that can be switched automatically in case of any technical issue with primary stream source with no action required from user. <br>
          My experience with WebGL, Three.js library and Blender software allows us to create most innovative 3D expo stands and  virtual/hybrid conferences.
          <br>
          <br>
          Job Responsibilities:
          <ul>
            <li>
              Design and implementation of IOS/Android/PWA application for high-level pharmaceutical companies such as Boehringer Ingelheim, Roche, Lilly, Adamed, Pfizer.
             
              Primary apps categories: 
             
                  medical calculators and quizzes,
                
                  web-based educational platforms,
               
                  visual presentation of financial  data and educational content,
                
                  schedules and reminders for drug intake.
                          
            </li>
            <li>              
              Application deployment and performance monitoring 
            </li>
            <li>
              Technical solutions development based on clients needs, analysis workload/timeload estimations
            </li>
            <li>
              Coordination of the product development process, task assignments within delivery team
            </li>
            <li>                	
              Training new team members
            </li>
          </ul>
        </div>
      </div>
      <div class="date_range_item">
        <div class="date">
          2012 - 2013
        </div>
        <div class="text">
          <h3>Junior Demandware Developer<br>Astound Commerce, Ukraine</h3>
          Has participated in the development of websites for FinishLine, Loreal and Giorgio Armani.
        </div>
      </div>
      <h2>Technologies</h2>
      <div class="date_range_item">
          <div class="date">
              Frameworks, libraries
            </div>
        <div class="text">
          Angular, Jasmine, Karma, Cypress, ngRx, Ionic, Vue.js, Three.js, Socket.io, Express.js, Mongoose, Angular Material, Bootstrap
        </div>
      </div>
      <div class="date_range_item">
          <div class="date">
              Languages
            </div>
        <div class="text">
          JavaScript, TypeScript, Node.js, Python<br><br>
          As student I was writing a lot of Pascal/Delphi, C++ code for solving educational problems.
          I'm not Swift or Java developer, but I can understand how Cordova/Capacitor plugins works.
        </div>
      </div>
     
      <div class="date_range_item">
        <div class="date">
            Software
        </div>
        <div class="text">
          Blender, Adobe XD, Adobe Photoshop, Adobe Illustrator, Git, Excel
        </div>
      </div>

      <h2>Education</h2>
      <div class="date_range_item">
        <div class="date">
          2008 - 2012
        </div>
        <div class="text">
          <h3>Ukraine, Vinnytsia State Pedagogical University</h3>
          “Mathematics, Physics and Technology Science”<br>
          Bachelor degree of Mathematics
          <br>
          As a student actively participated in different technical and science competitions, twice became the prize-winner of scientific competition “Microsoft Partners in Learning”. Also was working on development of different educational projects, including creating educational web-resources, platforms and software.
        </div>
      </div>
      
  </div>
  <div class="col_right">
      <h2>Personal information</h2>
      <div class="date_range_item">
          <div class="date">
              Name:
            </div>
        <div class="text">
           Viacheslav Komarivskyi
        </div>
      </div>
      <div class="date_range_item">
          <div class="date">
              Email:
            </div>
        <div class="text">
          viacheslav.komarivskyi@gmail.com
        </div>
      </div>
        <div class="date_range_item">
            <div class="date">
                Age:
            </div>
            <div class="text">
                32 years
            </div>
          </div>
      <div class="date_range_item">
          <div class="date">
              Location:
          </div>
          <div class="text">
              Piaseczno, Poland
          </div>
        </div>
        <h2>Languages</h2>
        <div class="date_range_item">
            <div class="date">
                Polish
              </div>
          <div class="text">
              Advanced
          </div>
        </div>
        <div class="date_range_item">
            <div class="date">
                English
              </div>
          <div class="text">
              Advanced
          </div>
        </div>        
        <div class="date_range_item">
            <div class="date">
                Ukrainian
              </div>
          <div class="text">
              Native
          </div>
        </div>
        <h2>Hobbies</h2>
        <div class="date_range_item">
          3D Printing, Gaming, Longboarding
        </div>
  </div>
</div>
<a  target="_blank" href="assets/Viacheslav-Komarivskyi-CV.pdf" class="btn" (click)="scrollTop('.about')" [ngClass]="{show: timeout}">
  <div class="image">
    <img src="assets/img/icons/download.svg" alt="">
  </div>
  <div class="btn-text">   
    Download CV
  </div>
</a>
</section>
<section class="contact">
  <h1>Contact</h1>
  <p>Drop me a line if you want to say "hi" or have a beer. I will be happy to hear from you: kom482@gmail.com</p>
</section>
</div>

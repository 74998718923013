<div class="container" >
    <div class="container-loader" [ngClass]="state" *ngIf="state !== 'done'">
        <div  class="loader"></div>
    </div>
    <div (mousemove)="mousemove($event)" (mousedown)="mousedown($event)" (mouseup)="mouseup($event)" [ngClass]="{hover : hoveredMesh}" class="renderer" #renderer></div>
    <!-- <div class="flag" *ngIf="activeMeshName" [ngStyle]="{ 'left': ''+activePositoin.x+'px', 'top': ''+activePositoin.y+'px'}">
        <div class="text">
            <span *ngIf="activeText">
                {{activeText}}
            </span>
            <div *ngIf="!activeText" class="loader"></div>
        </div>
        <div class="line"></div>
        <div class="circle"></div>
    </div> -->
</div>